.btn-primary {
  background: #4691D2;
  border-color: #4691D2;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  background-color: #356d9e;
  border-color: #356d9e; /*set the color you want here*/
}

.btn-secondary {
  background: #E1B928;
  border-color: #E1B928;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .open>.dropdown-toggle.btn-secondary {
  background-color: #a7891d;
  border-color: #a7891d; /*set the color you want here*/
}

.btn-info {
  color: white;
  background: #C8372D;
  border-color: #C8372D;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open>.dropdown-toggle.btn-info {
  color: white;
  background-color: #8d271f;
  border-color: #8d271f; /*set the color you want here*/
}